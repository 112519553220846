const venueTemplate = require('../../templates').venueTemplate;
const roomTemplate = require('../../templates').roomTemplate;

const venueData = Object.assign({}, venueTemplate, {
	id: 'riddle-house',
	name: 'RiddleHouse',
	website: 'https://www.escaperoom-riddlehouse.dk',
	locations: [
		{
			id: 'koege',
			cityId: 'koege',
			opened: '20190301',
			closed: true,
			address: 'Astersvej 23 D, 4600 Køge',
			coordinates: {lng: '12.1714246', lat: '55.4463581'},
		},
		{
			id: 'koege-2',
			cityId: 'koege',
			opened: '20190301',
			closed: true,
			address: 'Nørregade 4, 4600 Køge',
			coordinates: {lng: '12.1714246', lat: '55.4463581'},
		},
		{
			id: 'roskilde',
			cityId: 'roskilde',
			opened: '20200410',
			closed: true,
			address: 'Møllehusvej 1, 4000 Roskilde',
			coordinates: {lat: '55.634262', lng: '12.070747'},
		},
		{
			id: 'copenhagen',
			cityId: 'copenhagen',
			opened: '20201001',
			address: 'Gothersgade 35, 1. sal, 1123 København',
			coordinates: {lat: '55.682182', lng: '12.582156'},
		},
		{
			id: 'naestved',
			cityId: 'naestved',
			opened: '20210521',
			closed: true,
			address: 'Maglemølle 82, 4700 Næstved',
			coordinates: {lat: '', lng: ''},
		},
		{
			id: 'albertslund',
			cityId: 'albertslund',
			opened: '20230728',
			closed: null,
			address: 'Fængselsvej 39, 2620 Albertslund'
		}
	],
	rooms: [
		Object.assign({}, roomTemplate, {
			id: 'pablo-escobar',
			name: {da: 'The Colombian Hideout', en: 'The Colombian Hideout'},
			languages: ['da', 'en'],
			minimumAge: 12,
			difficulty: 85,
			locations: [{
				locationId: 'copenhagen',
				opened: '20161201', // 20200901 ?
				closed: null,
				rooms: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'seven',
			name: {da: 'Seven', en: 'Seven'},
			languages: ['da', 'en'],
			minimumAge: 12,
			difficulty: 75,
			locations: [{
				locationId: 'copenhagen',
				opened: '20161201', // 20200901 ?
				closed: null,
				rooms: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'the-last-passenger',
			name: {da: 'Den Sidste Passager', en: 'The Last Passenger'},
			languages: ['da', 'en'],
			minimumAge: 10,
			difficulty: 60,
			locations: [{
				locationId: 'copenhagen',
				opened: '20161201', // 20200901 ?
				closed: null,
				rooms: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'the-ring',
			name: {da: 'The Ring', en: 'The Ring'},
			languages: ['da', 'en'],
			minimumAge: 13,
			difficulty: 90,
			tagIds: ['live-actors'],
			locations: [{
				locationId: 'copenhagen',
				opened: '20161201', // 20200901 ?
				closed: null,
				rooms: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'professor-jones',
			name: {
				da: 'Professor Jones',
				en: 'Professor Jones'
			},
			
			languages: ['en'],
			difficulty: 70,
			locations: [{
				locationId: 'roskilde',
				opened: '20200612',
				closed: '20230228',
				rooms: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'slaughterhouse',
			name: {da: 'Slaughterhouse', en: 'Slaughterhouse'},
			languages: ['en'],
			minimumAge: 15,
			minimumAgeNoAdult: 15,
			difficulty: 80,
			locations: [{
				locationId: 'roskilde',
				opened: '20200612',
				closed: '20230228',
				rooms: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'the-witch',
			name: {da: 'The Witch', en: 'The Witch'},
			languages: ['da'],
			minimumAge: 15,
			minimumAgeNoAdult: 15,
			difficulty: 70,
			locations: [{
				locationId: 'roskilde',
				opened: '20200710',
				closed: '20230228',
				rooms: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'ragnarok',
			name: {da: 'Ragnarok', en: 'Ragnarok'},			
			languages: ['en'],
			minimumAge: 10,
			minimumAgeNoAdult: 15,
			difficulty: 85,
			locations: [{
				locationId: 'koege',
				opened: '20190301',
				closed: '20230228',
				rooms: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'spymaster',
			name: {da: 'Spymaster', en: 'Spymaster'},
			languages: ['en'],
			minimumAge: 10,
			minimumAgeNoAdult: 15,
			difficulty: 70,
			locations: [{
				locationId: 'koege',
				opened: '20190301',
				closed: '20230228',
				rooms: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'the-enigma-killer',
			name: {da: 'The Enigma Killer', en: 'The Enigma Killer'},
			minimumAge: 10,
			difficulty: 80,
			locations: [{
				locationId: 'naestved',
				opened: '20210521',
				closed: true,
				rooms: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'the-mystery-about-dannebroge',
			name: {da: 'Mysteriet om Dannebroge', en: 'The Mystery of Dannebroge'},
			minutes: 45,
			teamSizeMax: 8,
			minimumAgeNoAdult: 15,
			difficulty: 50,
			locations: [{
				locationId: 'koege-2',
				opened: '20200810',
				closed: true,
				rooms: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'olsen-banden-last-plan',
			name: {da: 'Olsen Bandens Sidste Plan', en: 'The Olsen Gang\'s Last Plan'},
			teamSizeMax: 7,
			minimumAgeNoAdult: 15,
			minimumAge: 8,
			difficulty: 60,
			locations: [{
				locationId: 'albertslund',
				opened: '20230728',
				closed: null,
				rooms: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'olsen-banden-biggest-coup',
			name: {da: 'Olsen Bandens Største Kup', en: 'The Olsen Gang\'s Biggest Coup'},
			teamSizeMax: 7,
			minimumAgeNoAdult: 15,
			minimumAge: 8,
			difficulty: 50,
			locations: [{
				locationId: 'albertslund',
				opened: '20230728',
				closed: null,
				rooms: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'escape',
			name: {da: 'Flugten fra Vridsløselille', en: 'The Escape from Vridsløselille'},
			teamSizeMax: 6,
			minimumAgeNoAdult: 15,
			minimumAge: 8,
			difficulty: 60,
			locations: [{
				locationId: 'albertslund',
				opened: '20230728',
				closed: null,
				rooms: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'urban-explorers',
			name: {da: 'Urban Explorers: The Prison', en: 'Urban Explorers: The Prison'},
			languages: ['en'],
			teamSizeMax: 6,
			minimumAgeNoAdult: 15,
			minimumAge: 12,
			difficulty: 75,
			locations: [{
				locationId: 'albertslund',
				opened: '20240701',
				closed: null,
				rooms: 1
			}]
		})
	]
});


export {venueData};