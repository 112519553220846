const venueTemplate = require('../../templates').venueTemplate;
const roomTemplate = require('../../templates').roomTemplate;

const venueData = Object.assign({}, venueTemplate, {
	id: 'city-bowling-holbaek',
	name: 'City Bowling Holbæk',	
	website: 'https://citybowling.dk/holbaek/escape-room/',
	locations: [{
		id: 'holbaek',
		cityId: 'holbaek',
		opened: '20200911',
		closed: null,
		address: 'Mellemvang 5, 4300 Holbæk',
	}],
	rooms: [
		Object.assign({}, roomTemplate, {
			id: 'escape-room',
			name: {da: 'Escape Room', en: 'Escape Room'},			
			minutes: 90,
			minimumAge: 16,
			locations: [{
				locationId: 'holbaek',
				opened: '20220311',
				closed: '20240823',
				rooms: 1
			}]
		}),
	]
});


export {venueData};